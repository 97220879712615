<template>
  <div v-if="isshowgrid">
    <va-card title="Customer Organization List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="Search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Organization Name (Max length cannot exceed 49 characters)</span>
            <va-input
              v-if="isCreate"
              v-model.trim="org_name"
              type="text"
              maxlength=49
              placeholder="Enter Organization Name"
              :error="!!orgNameErrors.length"
              :error-messages="orgNameErrors"
              required="true"
            />
            <va-input
              v-if="isUpdate"
              v-model="org_name"
              type="text"
              maxlength=49
              placeholder="Enter Organization Name"
              :error="!!orgNameErrors.length"
              :error-messages="orgNameErrors"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Technical Email</span>
            <va-input
              v-model.trim="technical_email"
              type="text"
              placeholder="Enter Technical Email"
              pattern="^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{1,4}$"
              :error="!!technicalEmailErrors.length"
              :error-messages="technicalEmailErrors"
              required="true"
            />
            <span class="va-form-label va-form-required-label">Report Email</span>
            <va-input
              v-model.trim="report_email"
              type="text"
              placeholder="Enter Report Email"
              pattern="^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{1,4}$"
              :error="!!reportEmailErrors.length"
              :error-messages="reportEmailErrors"
              required="true"
            />
            <span class="va-form-label va-form-required-label">Phone Number</span>
            <va-input
              v-model.trim="phone_no"
              type="text"
              maxlength=10
              pattern="[6-9]{1}[0-9]{9}"
              placeholder="Enter Phone Number"
              :error="!!phoneNoErrors.length"
              :error-messages="phoneNoErrors"
              required="true"
            />
            <span class="va-form-label va-form-required-label">State</span>
            <va-select
              v-if="isCreate"
              placeholder="Select State"
              searchable
              v-model="state"
              textBy="name"
              :options="stateList"
              :error="!!stateErrors.length"
              :error-messages="stateErrors"
              @input="getCityByState(state)"
              required="true"
              noClear
            />
            <va-input
              v-if="isUpdate"
              v-model="state"
              type="text"
              :disabled="true"
            />
            <span v-if="((city_flag && isCreate) || isUpdate)" class="va-form-label va-form-required-label">City</span>
            <va-select
              v-if="city_flag && isCreate"
              v-model="city"
              type="text"
              textBy="name"
              searchable
              :options="city_list"
              placeholder="Select City"
              :error="!!cityErrors.length"
              required="true"
              :error-messages="cityErrors"
            />
            <va-input
              v-if="isUpdate"
              v-model="city"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label va-form-required-label">Short Code</span>
            <va-input
              v-model.trim="short_code"
              type="text"
              placeholder="Enter Short Code"
              :error="!!shortCodeErrors.length"
              :error-messages="shortCodeErrors"
              required="true"
            />
            <span class="va-form-label va-form-required-label">Pincode</span>
            <va-input
              v-if="isCreate"
              v-model.trim="pincode"
              type="text"
              maxlength=6
              pattern="[1-9][0-9]{5}"
              placeholder="Enter Pincode"
              :error="!!pincodeErrors.length"
              :error-messages="pincodeErrors"
              required="true"
            />
            <va-input
              v-if="isUpdate"
              v-model="pincode"
              type="text"
              :disabled="true"
            />
            <span class="va-form-label">Gst Number</span>
            <va-input
              v-model.trim="gst_number"
              type="text"
              placeholder="Enter GST Number"
              :error="!!gstNumberErrors.length"
              :error-messages="gstNumberErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createCustomerOrg()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateCustomerOrg()">Update</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
import REGEX from '../../i18n/regex_pattern'
Vue.use(vueResource)
export default {
  name: 'customer_organization',
  beforeCreate () {
    // const loader = Vue.$loading.show({ width: 40, height: 40 })
    // this.$http.get(config.menu.host + '/customer_org').then(response => {
    //   loader.hide()
    //   this.customerOrgData = response.body
    //   this.isshowForm = false
    //   this.isshowgrid = true
      this.$http.get(config.menu.host + '/org/stateCity').then(re => {
        this.state_city_mapper = re.body
        this.state_city_mapper.map(function (item, i) {
          item.id = i + 1
        })
        this.stateList = this.state_city_mapper
        this.getAllCustomerOrg()
      })
    // }, error => {
    //   loader.hide()
    //   if (error && error.body) {
    //     Vue.notify({ text: error.body, type: 'error' })
    //   }
    // })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      adminRole: true,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      status: false,
      city_flag: false,
      orgFlag: false,
      org_name: '',
      reseller_org: '',
      technical_email: '',
      report_email: '',
      phone_no: '',
      state: '',
      city: '',
      pincode: '',
      short_code: '',
      gst_number: '',
      title: '',
      customerOrgData: [],
      orgArr: [],
      orgNameErrors: [],
      resellerOrgErrors: [],
      shortCodeErrors: [],
      technicalEmailErrors: [],
      reportEmailErrors: [],
      phoneNoErrors: [],
      cityErrors: [],
      stateErrors: [],
      pincodeErrors: [],
      gstNumberErrors: [],
      stateList: [],
    }
  },
  computed: {
    formReady () {
      return !this.orgNameErrors.length && !this.technicalEmailErrors.length && !this.reportEmailErrors.length && !this.phoneNoErrors.length && !this.stateErrors.length && !this.cityErrors.length && !this.pincodeErrors.length && !this.shortCodeErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '6%',
      }, {
        name: 'org_name',
        title: 'Organization Name',
      },
      {
        name: 'technical_email',
        title: 'Technical Email',
      },
      {
        name: 'phone_no',
        title: 'Phone Number',
      },
      {
        name: 'state',
        title: 'State',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.customerOrgData)
    },
  },
  methods: {
    getAllCustomerOrg () {
      var ref = this
      this.$http.get(config.menu.host + '/customer_org').then(response => {
        let index = 0
        this.customerOrgData = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          item.is_existing = true
          item.checkbox_value = false
          return item
        })
        this.isshowForm = false
        this.isshowgrid = true
      })
    },
    createCustomerOrg () {
      this.orgNameErrors = this.org_name ? [] : ['Organization Name is required']
      this.technicalEmailErrors = this.technical_email ? [] : ['Technical Email is required']
      this.reportEmailErrors = this.report_email ? [] : ['Report Email is required']
      this.phoneNoErrors = this.phone_no ? [] : ['Phone Number is required']
      this.stateErrors = this.state ? [] : ['State is required']
      this.cityErrors = this.city ? [] : ['City is required']
      this.pincodeErrors = this.pincode ? [] : ['Pincode is required']
      this.shortCodeErrors = this.short_code ? [] : ['Short Code is required']

      if (!this.formReady) {
        return
      }
      if (!REGEX.OrgName(this.org_name)) {
        return Vue.notify({ text: 'Org Name is Not valid', type: 'error'})
      }	
      var payload = {
        org_name: this.org_name,
        technical_email: this.technical_email,
        report_email: this.report_email,
        phone_no: this.phone_no,
        state: this.state.name,
        city: this.city.name,
        pincode: this.pincode,
        gst_number: this.gst_number,
        status: this.status,
        short_code: this.short_code,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/customer_org', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateCustomerOrg () {
      this.technicalEmailErrors = this.technical_email ? [] : ['Technical Email is required']
      this.reportEmailErrors = this.report_email ? [] : ['Report Email is required']
      this.phoneNoErrors = this.phone_no ? [] : ['Phone Number is required']
      this.pincodeErrors = this.pincode ? [] : ['Pincode is required']
      this.shortCodeErrors = this.short_code ? [] : ['Short Code is required']
      if (!this.formReady) {
        return
      }
      var payload = {
        org_name: this.org_name,
        technical_email: this.technical_email,
        report_email: this.report_email,
        phone_no: this.phone_no,
        state: this.state.name,
        city: this.city.name,
        pincode: this.pincode,
        gst_number: this.gst_number,
        status: this.status,
        short_code: this.short_code,
      }
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/customer_org/' + this.customer_org_id, payload).then(resp => {
        loader.hide()
        if (resp && resp.body) {
          Vue.notify({ text: resp.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    getCityByState (state) {
      this.city = ''
      var vm = this
      if (state.cities) {
        vm.city_flag = false
        state.cities.map(function (prop, i) {
          prop.id = i + 1
        })
        vm.city_list = state.cities
        vm.city_flag = true
      } else {
        vm.city_flag = false
      }
    },
    add () {
      this.title = 'Create Customer Organization'
      this.org_name = ''
      this.technical_email = ''
      this.report_email = ''
      this.phone_no = ''
      this.state = ''
      this.city = ''
      this.short_code = ''
      this.pincode = ''
      this.gst_number = ''
      this.status = true
      this.shortCodeErrors = []
      this.orgNameErrors = []
      this.technicalEmailErrors = []
      this.reportEmailErrors = []
      this.phoneNoErrors = []
      this.stateErrors = []
      this.cityErrors = []
      this.pincodeErrors = []
      this.gstNumberErrors = []
      this.cityErrors = []

      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
    },
    edit (row) {
      this.title = 'Update Customer Organization'
      this.customer_org_id = row.customer_org_id
      this.org_name = row.org_name
      this.technical_email = row.technical_email
      this.report_email = row.report_email
      this.phone_no = row.phone_no
      this.state = row.state
      this.city = row.city
      this.short_code = row.short_code
      this.pincode = row.pincode
      this.gst_number = row.gst_number
      this.status = !!row.status

      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllCustomerOrg()
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
